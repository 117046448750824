/* manrope-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/manrope-v14-latin-200.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-200.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/manrope-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-300.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/manrope-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-regular.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/manrope-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-500.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/manrope-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-600.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/manrope-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-700.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/manrope-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: url('../fonts/manrope-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/manrope-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/manrope-v14-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/manrope-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/manrope-v14-latin-800.svg#Manrope') format('svg'); /* Legacy iOS */
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Manrope';
  font-weight: 400;
}

:root{
  --main-font-color: rgb(170, 170, 170);
  --padding-large: 2rem;
  --padding-small: 1rem;
  --padding-xsmall: 0.5rem;
}

body{
  background-color: black;
  color: var(--main-font-color);
}

a{
  text-decoration: none;
  color: inherit;
}

.overlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}

.hero{
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle, rgb(9, 4, 22) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.hero-wrapper{
  display: flex;
  align-items: center;
  height: 92%;
  max-width: 1000px;
  margin: 0 auto;
  padding: var(--padding-large);
}

.hero-left{
  min-width: 500px;
  z-index: 5;
}

.hero-right{
  z-index: 1;
}

.canvas-temp{
  width: 500px;
  height: 500px;
}

.hero-title{
  padding: var(--padding-small) 0;
  font-size: 46px;
  font-weight: 600;
  color: white;
}

.hero-sub{
  line-height: 1.5rem;
  padding: var(--padding-small) 0;
}

.hero-actions{
  display: flex;
  padding: var(--padding-small) 0;
  align-items: center;
}

.theme-button{
  padding: 1rem 0;
  border-radius: 2rem;
  font-weight: bold;
  transition: all .2s ease-in;
}

.theme-button:hover{
  color: white;
  transition: all .2s ease-in;
}

.text-link{
  transition: all .2s ease-in;
}

.text-link:hover{
  color: white;
  transition: all .2s ease-in;
}

.project-item{
  display: flex;
  align-items: center;
}

.project-item-title{
  padding: var(--padding-xsmall) 0;
  font-weight: normal;
  color: var(--main-font-color);
  font-size: 1rem;
  transition: all .2s ease-in;
}

.project-item-sub{
  margin-left: var(--padding-xsmall);
  opacity: 0;
  font-size: 1rem;
  transition: all .2s ease-in;
}

.project-item:hover .project-item-title{
  font-weight: 900;
  color: white;
  transition: all .2s ease-in;
}

.project-item:hover .project-item-sub{
  opacity: 1;
  transition: all .2s ease-in;
}

.hero:has(#starfire:hover){
  background: radial-gradient(circle, rgb(22, 13, 4) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.starfire-bg{
  background: radial-gradient(circle, rgb(22, 13, 4) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.hero:has(#novacaine:hover){
  background: radial-gradient(circle, rgb(15, 4, 22) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.novacaine-bg{
  background: radial-gradient(circle, rgb(15, 4, 22) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.hero:has(#exttasy:hover){
  background: radial-gradient(circle, rgb(22, 4, 21) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.exttasy-bg{
  background: radial-gradient(circle, rgb(22, 4, 21) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.hero:has(#opium:hover){
  background: radial-gradient(circle, rgb(22, 4, 10) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.opium-bg{
  background: radial-gradient(circle, rgb(22, 4, 10) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.hero:has(#oxygen:hover){
  background: radial-gradient(circle, rgb(3, 15, 17) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.oxygen-bg{
  background: radial-gradient(circle, rgb(3, 15, 17) 0%, rgba(0,0,0,1) 95%);
  transition: all .2s ease-in;
}

.p-link{
  color: rgb(224, 224, 224);
  transition: all .2s ease-in;
}

.p-link:hover{
  color: rgb(255, 255, 255);
  transition: all .2s ease-in;
}

.hero-gallery{
  flex-direction: column;
}

.action-container{
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: var(--padding-large) 0;
}

.gallery{
  display: flex;
  align-self: flex-start;
}

.gallery-img{
  width: 18%;
  cursor: pointer;
  filter: brightness(50%);
  transition: all .2s ease-in;
}

.gallery-img:hover{
  filter: brightness(100%);
  transition: all .2s ease-in;
}


.gallery-img:not(:first-child){
  padding: 0 var(--padding-xsmall);
}

@media (max-width:1080px)  {
  .overlay{
    background-color: rgba(0, 0, 0, 0.5);
  }

  .hero-wrapper{
    padding: 0;
    max-height: 100vh;
  }

  .hero-left{
    position: absolute;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .canvas-temp{
    max-width: 100vw;
  }

  .hero-right{
    margin: 0 auto;
    padding: 0;
  }

  .hero-title{
    text-align: center;
  }

  .hero-left a{
    text-align: center;
  }

  .project-item{
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;
  }

  .project-item-title{
    text-align: center;
  }

  .project-item-sub{
    text-align: center;
  }

  .hero-right{
    position: relative;
  }

  .hero-actions{
    justify-content: center;
  }

  .hero-sub{
    padding: 1rem;
    text-align: center;
  }

  .action-container{
    align-self: auto;
  }

  .gallery{
    align-self:auto;
    margin: 0 auto;
    justify-content: center;
  }
}